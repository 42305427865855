/* Picture thumbnail on map */
.maplibregl-popup-content {
	padding: 5px !important;
	position: relative;
}

.pnx-map-thumb {
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	max-width: 130px;
	max-height: 130px;
}

@keyframes rotating {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}

.pnx-map-thumb-loader {
	background-color: rgb(230,230,230);
	border-radius: 65px;
	max-height: 60px;
	margin: 5px 35px;
	animation: rotating 2s linear infinite;
}

.pnx-map-thumb-legend {
	display: block;
	position: absolute;
	bottom: 5px;
	right: 5px;
	left: 5px;
	background-color: rgba(0,0,0,0.8);
	color: white;
	text-align: center;
	font-style: italic;
	font-size: 0.8em;
	margin: 0;
	padding: 0;
}

.maplibregl-marker {
	width: 60px;
}

/* Resize canvas for print */
@media print {
	.pnx-map.maplibregl-map .maplibregl-canvas-container {
		width: 100% !important;
		height: unset !important;
	}
}
