/* Maximized components */
pnx-photo-viewer .pnx-psv {
	position: absolute;
	inset: 0;
	z-index: 0;
}

/* No PSV loader */
pnx-photo-viewer .psv-loader {
	display: none;
}

/* Cornered grid layout */
pnx-photo-viewer pnx-cornered-grid::part(corner-top-left),
pnx-photo-viewer pnx-cornered-grid::part(corner-top),
pnx-photo-viewer pnx-cornered-grid::part(corner-top-right),
pnx-photo-viewer pnx-cornered-grid::part(corner-bottom-left),
pnx-photo-viewer pnx-cornered-grid::part(corner-bottom),
pnx-photo-viewer pnx-cornered-grid::part(corner-bottom-right) {
	gap: 10px;
	padding: 10px;
}

@media screen and (max-width: 576px) {
	pnx-photo-viewer pnx-cornered-grid::part(corner-top-right) {
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-start;
	}
}

/* Override legend positioning */
@media screen and (max-width: 576px) {
	pnx-photo-viewer pnx-widget-legend {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
	}

	pnx-photo-viewer pnx-widget-legend::part(panel) {
		border-radius: 0;
		border-top: none;
		border-left: none;
		border-right: none;
		width: unset;
		max-width: unset;
	}

	pnx-photo-viewer pnx-widget-player {
		margin-top: 60px;
	}
}

@media screen and (min-width: 576px) {
	pnx-photo-viewer pnx-widget-legend {
		position: absolute;
		left: 10px;
		top: 60px;
	}

	pnx-photo-viewer pnx-widget-legend {
		top: 10px;
	}
}
