pnx-button-group > pnx-button {
	display: inline-block;
}

/* Togglable in group */
pnx-button-group > pnx-togglable-group > pnx-button {
	width: 100%;
	height: 100%;
}

/* Row */
pnx-button-group[dir="row"] > :not(:first-child):not(:last-child)::part(btn),
pnx-button-group[dir="row"] > :not(:first-child):not(:last-child) > ::part(btn) {
	border-radius: 0;
	border-left: none;
	border-right: none;
}

pnx-button-group[dir="row"] > :first-child::part(btn),
pnx-button-group[dir="row"] > :first-child > ::part(btn) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: none;
}

pnx-button-group[dir="row"] > :last-child::part(btn),
pnx-button-group[dir="row"] > :last-child > ::part(btn) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left: none;
}

/* Column */
pnx-button-group[dir="column"] > :not(:first-child):not(:last-child)::part(btn),
pnx-button-group[dir="column"] > :not(:first-child):not(:last-child) > ::part(btn) {
	border-radius: 0;
	border-top: none;
	border-bottom: none;
}

pnx-button-group[dir="column"] > :first-child::part(btn),
pnx-button-group[dir="column"] > :first-child > ::part(btn) {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-bottom: none;
}

pnx-button-group[dir="column"] > :last-child::part(btn),
pnx-button-group[dir="column"] > :last-child > ::part(btn) {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-top: none;
}
