/* Colors */
:root, :host {
	--white: #ffffff;
	--black: #181818;
	--black-pale: #1b1a17;
	--red: #f70000;
	--red-pale: #ff726f;
	--grey: #f5f5f5;
	--grey-pale: #cfd2cf;
	--grey-semi-dark: #808080;
	--grey-dark: #3e3e3e;
	--blue: #2954e9;
	--blue-dark: #0a1f69;
	--blue-semi: #d7dffc;
	--blue-pale: #f2f5ff;
	--blue-geovisio: #34495e;
	--beige: #f5f3ec;
	--yellow: #fec868;
	--orange: #ff6f00;
	--orange-pale: #fffafa;
	--green: #7ec636;
	--green-pale: #f0ffee;
	--widget-bg: var(--white);
	--widget-bg-hover: var(--grey);
	--widget-bg-active: var(--blue);
	--widget-bg-inactive: var(--grey-pale);
	--widget-bg-primary: var(--blue-semi);
	--widget-bg-primary-hover: #e0e7ff;
	--widget-border-div: var(--grey-pale);
	--widget-border-btn: var(--blue);
	--widget-font: var(--grey-dark);
	--widget-font-active: var(--white);
	--widget-font-direct: var(--blue);
	--font-family: "Atkinson Hyperlegible Next", sans-serif;
}

.pnx-hidden { display: none !important; }

/* Hidden elements on mobile */
@container (max-width: 576px) {
	.pnx-mobile-hidden { display: none !important; }
}

/* Hidden elements on print */
@media print {
	.pnx-print-hidden { display: none !important; }
}
