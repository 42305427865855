pnx-widget-geosearch .maplibregl-ctrl {
	box-shadow: none !important;
	background: none !important;
}

pnx-widget-geosearch .maplibregl-ctrl-geolocate {
	border-radius: 15px;	
}

pnx-widget-geosearch pnx-search-bar[reduced] .maplibregl-ctrl-geolocate {
	width: 40px;
}

pnx-widget-geosearch pnx-search-bar:not([reduced]) .maplibregl-ctrl-geolocate {
	margin-left: -5px;
}

pnx-widget-geosearch pnx-search-bar:not([reduceable])::part(input) {
	width: 300px;
	max-width: 30vw;
}