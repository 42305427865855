/* Maximized components */
pnx-viewer .pnx-map.maplibregl-map,
pnx-viewer .pnx-psv {
	position: absolute;
	inset: 0;
	z-index: 0;
}

/* No PSV loader */
pnx-viewer .psv-loader {
	display: none;
}

/* Search bar over other components */
pnx-widget-geosearch {
	z-index: 129;
}

/* Cornered grid layout */
pnx-viewer pnx-cornered-grid::part(corner-top-left),
pnx-viewer pnx-cornered-grid::part(corner-top),
pnx-viewer pnx-cornered-grid::part(corner-top-right),
pnx-viewer pnx-cornered-grid::part(corner-bottom-left),
pnx-viewer pnx-cornered-grid::part(corner-bottom),
pnx-viewer pnx-cornered-grid::part(corner-bottom-right) {
	gap: 10px;
	padding: 10px;
}

@media screen and (max-width: 576px) {
	pnx-viewer pnx-cornered-grid::part(corner-top-right) {
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-start;
	}
}

/* Mini component */
pnx-viewer pnx-mini {
	box-sizing: border-box;
	aspect-ratio: 1/1;
	width: 250px;
	height: 250px;
	position: relative;
	display: block;
	z-index: 120;
}

@media screen and (max-width: 576px) {
	pnx-viewer pnx-mini {
		width: unset;
		height: unset;
	}
}

pnx-viewer pnx-mini .psv-container,
pnx-viewer pnx-mini .pnx-map {
	border-radius: 10px;
}

/* Hidden elements depending on focus */
pnx-viewer:not(pnx-viewer[focus="map"]) .pnx-only-map,
pnx-viewer:not(pnx-viewer[focus="pic"]) .pnx-only-psv {
	display: none;
}

/* Override legend positioning */
@media screen and (max-width: 576px) {
	pnx-viewer[focus="map"] pnx-widget-legend {
		display: none;
	}

	pnx-viewer[focus="pic"] pnx-widget-legend {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
	}

	pnx-viewer[focus="pic"] pnx-widget-legend::part(panel) {
		border-radius: 0;
		border-top: none;
		border-left: none;
		border-right: none;
		width: unset;
		max-width: unset;
	}

	pnx-viewer[focus="pic"] pnx-widget-player {
		margin-top: 60px;
	}
}

@media screen and (min-width: 576px) {
	pnx-viewer pnx-widget-legend {
		position: absolute;
		left: 10px;
		top: 60px;
	}

	pnx-viewer[focus="pic"] pnx-widget-legend {
		top: 10px;
	}
}

/* Hide default map legend */
pnx-viewer .pnx-map .maplibregl-ctrl-attrib {
	display: none;
}

/* Override geosearch sizing */
@media screen and (max-width: 576px) {
	pnx-widget-geosearch pnx-search-bar:not([reduced])::part(container) {
		width: 250px;
		max-width: 40vw;
	}
}