/* General layout */
pnx-editor {
	display: flex;
	flex-direction: column;
}

pnx-editor .pnx-map,
pnx-editor .pnx-psv {
	height: 50%;
}

/* Map background widget */
pnx-editor pnx-map-background {
	position: absolute;
	padding: 7px;
	z-index: 120;
	border-radius: 10px;
	border: 1px solid var(--widget-border-div);
	background-color: var(--widget-bg);
  	color: var(--widget-font);
	bottom: 10px;
	left: 10px;
}
